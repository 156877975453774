import { httpClient } from '../../infrastructure/admi-client/http-client';
import { Building, BuildingSearch, LocationSearch, RealEstate } from './global-tagger.dto';

export const admiGlobalTaggerService = {
    async loadRealEstateData(dataSearch: LocationSearch): Promise<RealEstate> {
        const response = await httpClient.get<RealEstate>('/api/v1/buildings', {
            params: {
                lat: dataSearch.location.lat,
                lng: dataSearch.location.lng,
                radius: dataSearch.radius,
                layers: dataSearch.layers,
                settings: {
                    maxSystemCapacityAdjustment: dataSearch.maxSystemCapacityAdjustment,
                },
            },
        });

        return response.data;
    },

    async loadBuildingData(search: BuildingSearch): Promise<Building> {
        const response = await httpClient.get<Building>(`/api/v1/buildings/${search.uuid}`, {
            params: {
                settings: {
                    maxSystemCapacityAdjustment: search.maxSystemCapacityAdjustment,
                },
            },
        });

        return response.data;
    }
};
