import React from 'react';
import { Sheet, IconButton, Typography, FormControl, Input, FormLabel, Alert, Stack } from '@mui/joy';
import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { HeatPumpLoadProfileDto } from '../pv-calculator.dto';

export default function HeatPumpLoad(props: {
    value: HeatPumpLoadProfileDto;
    onChange: (loadProfile: HeatPumpLoadProfileDto) => void;
    onDelete: () => void;
}) {
    const { t } = useTranslation();

    function onChange(updated: Partial<HeatPumpLoadProfileDto>) {
        props.onChange({
            ...props.value,
            ...updated,
        });
    }

    return (
        <Sheet variant="outlined" sx={{ p: 2 }}>
            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => props.onDelete()}>
                <Delete />
            </IconButton>
            <Typography level="h4">{t('Heat pump')}</Typography>
            <FormControl>
                <FormLabel>{t('Annual heat demand (kWh)')}</FormLabel>
                <Input
                    type="number"
                    value={props.value.annual_heat_demand}
                    onChange={(e) => onChange({ annual_heat_demand: parseFloat(e.target.value) })}
                />
            </FormControl>
            <FormControl>
                <FormLabel>{t('Annual COP')}</FormLabel>
                <Input
                    type="number"
                    value={props.value.annual_cop}
                    onChange={(e) => onChange({ annual_cop: parseFloat(e.target.value) })}
                />
            </FormControl>
            <Alert color="warning" sx={{ mt: 1 }}>
                <Stack>
                    <Typography level="body-sm">
                        {t('A specialized heat pump load profile is only available for the following building types:')}
                    </Typography>
                    <ul>
                        <li>KINDERGARTEN</li>
                    </ul>
                    <Typography level="body-sm">
                        {t(
                            'For all other building types, the standard profile is used. Note that this is less accurate and might lead to misleading designs.',
                        )}
                    </Typography>
                </Stack>
            </Alert>
        </Sheet>
    );
}
