import { Button, CircularProgress, Grid, Table } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { admiOnsiteService } from './admi-onsite.service';
import { OnsiteDto, OnsiteListResponseDto } from './onsite.dto';
import ReactPaginate from 'react-paginate';
import { Link, useSearchParams } from 'react-router';
import './OnsiteListPage.css';

function OnsiteListPage() {
    const [onsites, setOnsites] = useState<OnsiteListResponseDto>();
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const { t } = useTranslation();

    useEffect(() => {
        loadOnsites();
    }, [searchParams]);

    async function loadOnsites() {
        setIsLoading(true);

        const page = Number(searchParams.get('page')) || 1;

        try {
            const response = await admiOnsiteService.getOnsiteList({ page });
            setOnsites(response);
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    function changePage(page: number) {
        setSearchParams({ page: page.toString() });
    }

    return (
        <Grid container direction="row" xs={12} spacing={2} alignContent="flex-start">
            <Grid xs={12}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('Title')}</th>
                                <th>{t('Created at')}</th>
                                <th>{t('Actions')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {onsites?.data.map((onsite: OnsiteDto) => (
                                <tr key={onsite.reference}>
                                    <td>{onsite.title}</td>
                                    <td>{onsite.createdAt.toString()}</td>
                                    <td>
                                        <Link to={`/dev/onsite/${onsite.reference}/tender-form`}>
                                            <Button variant="outlined">{t('Open Tender Form')}</Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </Grid>

            {onsites?.data !== undefined && onsites.data.length > 0 && (
                <Grid xs={12} container justifyContent="center">
                    <ReactPaginate
                        initialPage={Number(searchParams.get('page')) - 1 || 0}
                        pageCount={onsites.meta.lastPage}
                        marginPagesDisplayed={2}
                        onPageChange={(selectedItem: any) => changePage(selectedItem.selected + 1)}
                        containerClassName={'container'}
                        previousLinkClassName={'page'}
                        breakClassName={'page'}
                        nextLinkClassName={'page'}
                        pageClassName={'page'}
                        disabledClassName={'disabled'}
                        activeClassName={'active'}
                    />
                </Grid>
            )}
        </Grid>
    );
}

export default connect()(OnsiteListPage);
