import html2canvas from 'html2canvas';

export declare type Options = {
    top: number;
    left: number;
    bottom: number;
    right: number;
    useCORS: boolean;
};

/**
 * A hook that provides a screenshot function that uses a custom DOM renderer.
 * 
 * Advantages:
 *  - It works with cross-origin content (like Google Maps)
 * 
 * Disadvantages:
 *  - It strongly depends on the browser settings (e.g. resolution, dpi scaling, zoom)
 *    leading to an inconsistent experience
 * 
 * As an alternative, consider using the `useSvgForeignObjectScreenshot` hook.
 */
export default function useDOMRendererScreenshot() {
    return {
        screenshot: async (element: HTMLElement, options: Options) => {
            const cropper = document.createElement('canvas').getContext('2d');

            if (!cropper) {
                throw new Error('Could not create a canvas context');
            }

            return html2canvas(element, { useCORS: options.useCORS, scale: 1 }).then((c) => {
                cropper.canvas.width = c.width - options.right - options.left;
                cropper.canvas.height = c.height - options.top - options.bottom;

                cropper.drawImage(
                    c,
                    options.left,
                    options.top,
                    cropper.canvas.width,
                    c.height - options.top - options.bottom,
                    0,
                    0,
                    cropper.canvas.width,
                    cropper.canvas.height,
                );

                return cropper.canvas;
            });
        },
    };
}
