import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum NotificationSeverity {
    Info,
    Success,
    Warning,
    Error,
}

interface NotificationsState {
    snackbar: {
        message: string;
        severity: NotificationSeverity;
    } | null;
}

const initialState: NotificationsState = {
    snackbar: null,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        showSnackbar(state, action: PayloadAction<{ message: string; severity: NotificationSeverity }>) {
            state.snackbar = action.payload;
        },
        hideSnackbar(state) {
            state.snackbar = null;
        },
    },
});

export const { showSnackbar, hideSnackbar } = notificationsSlice.actions;
export default notificationsSlice.reducer;
