import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Snackbar } from '@mui/joy';

import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import { hideSnackbar, NotificationSeverity } from './notifications.slice';

const NOTIFICATION_HIDE_DURATION_MS = 8000;

export default function NotificationSnackbar() {
    const snackbar = useAppSelector((state) => state.notifications.snackbar);
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const mapColor = (severity: NotificationSeverity) => {
        switch (severity) {
            case NotificationSeverity.Info:
                return 'neutral';
            case NotificationSeverity.Success:
                return 'success';
            case NotificationSeverity.Warning:
                return 'warning';
            case NotificationSeverity.Error:
                return 'danger';
            default:
                throw new Error('unknown severity');
        }
    };

    const color = mapColor(snackbar?.severity ?? NotificationSeverity.Info);

    return (
        <Snackbar
            open={snackbar !== null}
            onClose={() => dispatch(hideSnackbar())}
            variant="soft"
            color={color}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={NOTIFICATION_HIDE_DURATION_MS}
            endDecorator={
                <Button onClick={() => dispatch(hideSnackbar())} variant="soft" size="sm" color={color}>
                    {t('Dismiss')}
                </Button>
            }
        >
            {snackbar?.message}
        </Snackbar>
    );
}
