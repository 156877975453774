import * as React from 'react';

import Box from '@mui/joy/Box';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { useMatches } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function AppBreadcrumb() {
    const { t } = useTranslation();
    
    let matches: any = useMatches();

    matches = matches.filter((page: any) => page?.handle?.title);

    if (matches.length <= 1) {
        return <></>;
    }

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon />} sx={{ pl: 0 }}>
                {matches.map((page: any, i: number) => {
                    // first
                    if (page.id === 'homepage') {
                        return (
                            <Link underline="none" href={page.pathname} aria-label="Home" key={'breadcrumpElement' + i}>
                                <HomeRoundedIcon />
                            </Link>
                        );

                        // last
                    } else if (i + 1 === matches.length) {
                        return (
                            <Typography color="neutral" fontWeight={500} fontSize={12} key={'breadcrumpElement' + i}>
                                {t(page?.handle?.title)}
                            </Typography>
                        );

                        // others
                    } else {
                        return (
                            <Link
                                underline="hover"
                                href={page.pathname}
                                fontSize={12}
                                fontWeight={500}
                                key={'breadcrumpElement' + i}
                            >
                                {t(page?.handle?.title)}
                            </Link>
                        );
                    }
                })}
            </Breadcrumbs>
        </Box>
    );
}
