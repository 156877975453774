import * as React from 'react';

import { Outlet } from 'react-router';

export default function TestLayout() {
    return (
        <>
            <Outlet />
        </>
    );
}
