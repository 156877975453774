import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    FormControl,
    FormLabel,
    Input,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Grid,
    Skeleton,
    Box,
} from '@mui/joy';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import classNames from 'classnames';

import SolarPowerIcon from '@mui/icons-material/SolarPower';
import PreviewIcon from '@mui/icons-material/Preview';
import HandymanIcon from '@mui/icons-material/Handyman';
import RefreshIcon from '@mui/icons-material/Refresh';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import { formFieldBuilder } from 'common/formikUtils';
import { formValidationSchema, INITIAL_FORM_VALUES } from './formDefinitions';
import PVInput, { OptimizerMode, PVInputParams } from 'features/pv-calculator/components/PVInput';
import { Optimizer } from 'features/pv-calculator/pv-calculator.dto';
import SalesResult, { SALES_RESULT_WIDTH, SALES_RESULT_HEIGHT } from 'features/sales/SalesResult';
import SalesPrint from 'features/sales/SalesPrint';
import EnergyConsumptionComponents from 'features/sales/components/EnergyConsumptionComponents';
import Search from './components/Search';
import Map, { TakeScreenshotOptions } from './components/Map';
import './PvAnalysisCreatePage.css';
import { admiPVAnalysisService } from './admi-pv-analysis.service';
import { Business, MapOutlined, Tune } from '@mui/icons-material';
import SelectedBuildings from './components/SelectedBuildings';
import PvSizeSlider from './components/PvSizeSlider';
import { useAppDispatch, useAppSelector } from 'redux-app-hooks';
import { NotificationSeverity, showSnackbar } from 'features/notifications/notifications.slice';
import { calculatePvResults, loadPvAnalysisVariant, resetState } from './pv-analysis.slice';
import PVOptimizationInsights from 'features/pv-calculator/components/PVOptimizationInsights';

export default function PvAnalysisCreatePage() {
    const { editedVariant, errorMessage, isCalculationLoading } = useAppSelector((state) => state.pvAnalysis);
    const { pvAnalysisUuid, pvAnalysisName, name, calculatorResults, buildings: selectedBuildings } = editedVariant;
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [screenshotOptions, setScreenshotOptions] = useState<TakeScreenshotOptions | null>(null);
    const [screenshotDataUrl, setScreenshotDataUrl] = useState<string | null>(null);
    const [pvParams, setPvParams] = useState<PVInputParams>(editedVariant.pvParams);
    const [calculatorResultsDirty, setCalculatorResultsDirty] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: INITIAL_FORM_VALUES,
        validationSchema: formValidationSchema,
        onSubmit: createPvAnalysis,
    });

    const formField = (name: string) => formFieldBuilder(formik, name);

    useEffect(() => {
        if (screenshotDataUrl) {
            URL.revokeObjectURL(screenshotDataUrl);
        }
        if (screenshotOptions) {
            setScreenshotDataUrl(URL.createObjectURL(screenshotOptions.blob));
        }
    }, [screenshotOptions]);

    // Initiate loading an existing PV analysis variant, if an ID is provided
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            dispatch(loadPvAnalysisVariant(id))
                .unwrap()
                .then((variant) => {
                    admiPVAnalysisService
                        .getPVAnalysisMapScreenshotBlob(variant.uuid)
                        .then((blob) => {
                            if (variant.mapScreenshot) {
                                setScreenshotOptions({
                                    center: variant.mapScreenshot.center,
                                    zoom: variant.mapScreenshot.zoom,
                                    blob,
                                });
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            dispatch(
                                showSnackbar({
                                    message: t('Map screenshot not available'),
                                    severity: NotificationSeverity.Error,
                                }),
                            );
                        });
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(
                        showSnackbar({
                            message: t(`Failed to load PV Analysis variant: ${JSON.stringify(error)}`),
                            severity: NotificationSeverity.Error,
                        }),
                    );
                })
                .finally(() => {
                    setCalculatorResultsDirty(false);
                });
        } else {
            dispatch(resetState());
        }
    }, [id]);

    useEffect(() => {
        formik.setValues({ pvAnalysisName, name });
    }, [pvAnalysisName, name]);

    useEffect(() => {
        setPvParams(editedVariant.pvParams);
    }, [editedVariant.pvParams]);

    useEffect(() => {
        setCalculatorResultsDirty(true);
    }, [selectedBuildings]);

    function calculatePv() {
        if (selectedBuildings.length > 0) {
            setCalculatorResultsDirty(false);
            dispatch(calculatePvResults({ pvParams, selectedBuildings }));
        } else {
            dispatch(
                showSnackbar({
                    message: t('Please select at least one building.'),
                    severity: NotificationSeverity.Warning,
                }),
            );
        }
    }

    async function createPvAnalysis(values: typeof INITIAL_FORM_VALUES) {
        console.debug('createPvAnalysis', values);

        try {
            if (!screenshotOptions) {
                dispatch(
                    showSnackbar({
                        message: 'Please take a screenshot of the map first.',
                        severity: NotificationSeverity.Warning,
                    }),
                );
                return;
            }

            if (!calculatorResults) {
                dispatch(
                    showSnackbar({
                        message: 'Please run the PV calculation first.',
                        severity: NotificationSeverity.Warning,
                    }),
                );
                return;
            }

            await admiPVAnalysisService.createPVAnalysisVariant(
                values.name,
                editedVariant.maxSystemCapacityAdjustment,
                selectedBuildings,
                { center: screenshotOptions.center, zoom: screenshotOptions.zoom },
                screenshotOptions.blob,
                {
                    optimizer: pvParams.optimizer,
                    buildingType: pvParams.buildingType,
                    fundingRate: pvParams.fundingRate,
                    loadProfiles: pvParams.loadProfiles,
                    targetProduction:
                        pvParams.productionOptimizerMode === OptimizerMode.Manual ? pvParams.targetProduction : null,
                    batteryCapacityFactor:
                        pvParams.batteryOptimizerMode === OptimizerMode.Manual ? pvParams.batteryKwpFactor : null,
                    limitTo100Kwp: pvParams.constrainFullRoofTo100KWp,
                },
                admiPVAnalysisService.mapPvCalculatorDtoToAppDto(calculatorResults.optimizedResult),
                admiPVAnalysisService.mapPvCalculatorDtoToAppDto(calculatorResults.fullRoofResult),
                editedVariant.pvAnalysisUuid,
                values.pvAnalysisName,
            );

            dispatch(
                showSnackbar({
                    message: t('PV Analysis created successfully.'),
                    severity: NotificationSeverity.Success,
                }),
            );
            navigate('/dev/pv-analysis/global');
        } catch (error) {
            console.error(error);
            if (error instanceof AxiosError) {
                if (error.message[0]) {
                    dispatch(showSnackbar({ message: error.message[0], severity: NotificationSeverity.Error }));
                }
            }
        }
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container direction="row" xs={12} spacing={5}>
                <Grid xs={12}>
                    <Typography level="h2">{t('New PV Analysis')}</Typography>
                </Grid>

                <Grid xs={12}>
                    <AccordionGroup size="md">
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <HandymanIcon /> {t('General Information')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormControl required>
                                    <FormLabel>{t('PV Analysis Name')}</FormLabel>
                                    <Input
                                        placeholder={t('PV Analysis Name')}
                                        disabled={!!pvAnalysisUuid}
                                        {...formField('pvAnalysisName')}
                                    />
                                </FormControl>

                                <FormControl required sx={{ mt: 2 }}>
                                    <FormLabel>{t('Variation Name')}</FormLabel>
                                    <Input placeholder={t('Varation Name')} {...formField('name')} />
                                </FormControl>

                                {editedVariant.author && (
                                    <>
                                        <FormLabel sx={{ mt: 2 }}>{t('Author')}</FormLabel>
                                        <Typography level="body-sm">{editedVariant.author}</Typography>
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <MapOutlined /> {t('Search')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <Search />
                                </Grid>
                                <Grid xs={12}>
                                    <Map takeScreenshot={setScreenshotOptions} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <Tune /> {t('Max. PV System installation capacity')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <PvSizeSlider />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={false}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <Business /> {t('Buildings')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid xs={12}>
                                    <SelectedBuildings />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <SolarPowerIcon /> {t('PV Calculator')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PVInput
                                    params={pvParams}
                                    onChange={(value) => {
                                        setPvParams(value);
                                        setCalculatorResultsDirty(true);
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <PreviewIcon /> {t('PV Analysis Result')}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Box sx={{ mb: 1 }}>
                                    <Button
                                        onClick={calculatePv}
                                        startDecorator={<RefreshIcon />}
                                        disabled={!calculatorResultsDirty}
                                    >
                                        {t('(Re-)calculate')}
                                    </Button>
                                </Box>

                                <Skeleton
                                    variant="rectangular"
                                    width={SALES_RESULT_WIDTH}
                                    height={SALES_RESULT_HEIGHT}
                                    loading={isCalculationLoading}
                                />

                                {!isCalculationLoading && (
                                    <>
                                        {calculatorResults?.optimizedResult && calculatorResults?.fullRoofResult && (
                                            <Box
                                                className={classNames({
                                                    'pv-analysis-result': true,
                                                    'pv-analysis-result-dirty': calculatorResultsDirty,
                                                })}
                                            >
                                                <SalesPrint
                                                    projectName={formik.values.pvAnalysisName}
                                                    energyConsumption={
                                                        <EnergyConsumptionComponents
                                                            components={calculatorResults.optimizedResult.load_summary}
                                                            baseUsageEstimated={calculatorResults.baseUsageEstimated}
                                                        />
                                                    }
                                                    plot={
                                                        <>
                                                            {screenshotDataUrl && (
                                                                <img src={screenshotDataUrl} style={{ width: 378 }} />
                                                            )}
                                                            {!screenshotDataUrl && (
                                                                <Typography level="body-sm">
                                                                    {t('Please take a screenshot of the map first.')}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    <SalesResult
                                                        result={calculatorResults.optimizedResult}
                                                        fundingRate={calculatorResults.fundingRate}
                                                        optimizer={calculatorResults.optimizer}
                                                    />
                                                    <SalesResult
                                                        result={calculatorResults.fullRoofResult}
                                                        fundingRate={0}
                                                        optimizer={Optimizer.FULL_ROOF}
                                                    />
                                                </SalesPrint>
                                            </Box>
                                        )}

                                        {!calculatorResults && (
                                            <Typography>{t('Please run the PV calculation first.')}</Typography>
                                        )}
                                    </>
                                )}
                            </AccordionDetails>
                        </Accordion>

                        <Accordion defaultExpanded={true}>
                            <AccordionSummary>
                                <Typography level="title-lg" className="pv-analysis-section-tab-title">
                                    <TroubleshootIcon /> {t('Optimization Insights')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {calculatorResults && (
                                    <PVOptimizationInsights result={calculatorResults.optimizedResult} />
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </AccordionGroup>
                </Grid>

                <Grid xs={12}>
                    <Button type="submit" loading={formik.isSubmitting}>
                        {t('Create New PV Analysis')}
                    </Button>
                </Grid>

                {errorMessage && (
                    <Grid xs={12}>
                        <Alert color="danger">{t(errorMessage)}</Alert>
                    </Grid>
                )}
            </Grid>
        </form>
    );
}
