import { Sheet, Table, Typography } from '@mui/joy';
import React, { Fragment } from 'react';

import { ProfitLossItem } from '../pv-calculator.dto';

const GROUP_NAME_MAPPING = new Map([
    ['OneTimeCostItem', 'One-time costs'],
    ['YearlyCostItem', 'Yearly costs'],
    ['DepreciationCostItem', 'Depreciation costs'],
    ['OneTimeProfitItem', 'One-time profits'],
]);

export default function PVProfitLossStatement({ profitLossStatement }: { profitLossStatement: ProfitLossItem[] }) {
    const groupedItems = profitLossStatement.reduce((acc, item) => {
        if (!acc[item.type]) {
            acc[item.type] = [];
        }
        acc[item.type].push(item);
        return acc;
    }, {} as Record<string, ProfitLossItem[]>);

    return (
        <Sheet sx={{ width: '70rem', height: '30rem', overflow: 'auto' }}>
            <Table stickyHeader stripe="odd" hoverRow size="sm">
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Amount</th>
                        <th>Year (0-30)</th>
                        <th>Inflation rate</th>
                        <th>Lifetime</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(groupedItems).map(([groupName, items]) => (
                        <Fragment key={groupName}>
                            <tr style={{ backgroundColor: '#bbd3df' }}>
                                <td colSpan={5}>
                                    <Typography level="title-sm" fontWeight="bold">
                                        {GROUP_NAME_MAPPING.get(groupName)}
                                    </Typography>
                                </td>
                            </tr>

                            {items.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.amount.toFixed(2)} €</td>
                                    {'year_index' in item ? <td>{item.year_index}</td> : <td></td>}
                                    {'inflation_rate' in item ? <td>{item.inflation_rate * 100} %</td> : <td></td>}
                                    {'lifetime' in item ? <td>{item.lifetime} years</td> : <td></td>}
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    );
}
