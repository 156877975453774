import { Button } from '@mui/joy';
import React, { useRef, ReactNode } from 'react';
import { SaveAlt } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { SALES_RESULT_WIDTH, SALES_RESULT_HEIGHT } from './SalesResult';
import useSvgForeignObjectScreenshot from 'infrastructure/image/useSvgForeignObjectScreenshot';

export default function SalesPrint({
    children,
    projectName,
    energyConsumption,
    plot,
}: {
    children: ReactNode;
    projectName: string;
    energyConsumption: ReactNode;
    plot: ReactNode;
}) {
    const { t } = useTranslation();

    const salesPrintRef = useRef(null);
    const screenshot = useSvgForeignObjectScreenshot();

    const downloadImage = async () => {
        if (salesPrintRef.current === null) {
            return;
        }

        const canvas = await screenshot.screenshot(salesPrintRef.current, {
            width: SALES_RESULT_WIDTH,
            height: SALES_RESULT_HEIGHT,
        });

        const link = document.createElement('a');
        link.download = `${projectName}.png`.replaceAll(' ', '_');
        link.href = canvas.toDataURL('image/png');
        link.click();
    };

    return (
        <div className="sales sales_count1">
            <div id="objects" ref={salesPrintRef}>
                <h3>{projectName}</h3>
                <article>
                    <div className="section_img">
                        {energyConsumption}
                        {plot}
                    </div>

                    <div className="section_variants">
                        {children}
                    </div>
                </article>
            </div>

            <div style={{ float: 'left', paddingBottom: 10 }}>
                <Button variant="soft" onClick={() => downloadImage()} startDecorator={<SaveAlt />}>
                    {t('Save Image')}
                </Button>
            </div>
        </div>
    );
}
