import {
    PVAnalysisDto,
    PVAnalysisSearchDto,
    PVAnalysisVariantDto,
    PvAnalysisVariationBuilding,
    PvAnalysisVariationCalculatorParams,
    PvAnalysisVariationCalculatorResult,
    PvAnalysisVariationMapScreenshot,
} from './pv-analysis.dto';
import { PaginatedResponseDto } from 'infrastructure/admi-client/response.dto';
import { httpClient } from 'infrastructure/admi-client/http-client';
import { PVResultDto } from 'features/pv-calculator/pv-calculator.dto';

export const admiPVAnalysisService = {
    getPVAnalysisList: async (search: PVAnalysisSearchDto): Promise<PaginatedResponseDto<PVAnalysisDto>> => {
        const response = await httpClient.get<PaginatedResponseDto<PVAnalysisDto>>(
            `/api/v1/pv-analysis?page=${search.page}`,
        );

        return response.data;
    },

    getPVAnalysisVariants: async (
        uuid: string,
        search: PVAnalysisSearchDto,
    ): Promise<PaginatedResponseDto<PVAnalysisVariantDto>> => {
        const response = await httpClient.get<PaginatedResponseDto<PVAnalysisVariantDto>>(
            `/api/v1/pv-analysis/${uuid}/variations?page=${search.page}&perPage=${search.perPage}`,
        );

        return response.data;
    },

    async createPVAnalysisVariant(
        name: string,
        maxSystemCapacityAdjustment: number,
        buildings: PvAnalysisVariationBuilding[],
        mapScreenshot: PvAnalysisVariationMapScreenshot,
        mapScreenshotImageData: Blob,
        calculatorParams: PvAnalysisVariationCalculatorParams,
        calculatorOptimizedResult: PvAnalysisVariationCalculatorResult,
        calculatorFullRoofResult: PvAnalysisVariationCalculatorResult,
        pvAnalysisUuid?: string,
        pvAnalysisName?: string,
    ): Promise<PVAnalysisVariantDto> {
        // Post as multipart/form-data
        const formData = new FormData();
        if (pvAnalysisUuid) {
            formData.append('pvAnalysisUuid', pvAnalysisUuid);
        }
        if (pvAnalysisName) {
            formData.append('pvAnalysisName', pvAnalysisName);
        }
        formData.append('name', name);
        formData.append('maxSystemCapacityAdjustment', maxSystemCapacityAdjustment.toString());
        formData.append('buildings', JSON.stringify(buildings));
        formData.append('mapScreenshot', JSON.stringify(mapScreenshot));
        formData.append('mapImageData', mapScreenshotImageData);
        formData.append('calculatorParams', JSON.stringify(calculatorParams));
        formData.append('calculatorOptimizedResult', JSON.stringify(calculatorOptimizedResult));
        formData.append('calculatorFullRoofResult', JSON.stringify(calculatorFullRoofResult));

        const response = await httpClient.post<PVAnalysisVariantDto>('/api/v1/pv-analysis/variations', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    },

    async getPVAnalysisVariant(uuid: string): Promise<PVAnalysisVariantDto> {
        const response = await httpClient.get<PVAnalysisVariantDto>(`/api/v1/pv-analysis/variations/${uuid}`);
        return response.data;
    },

    async getPVAnalysisMapScreenshotBlob(uuid: string): Promise<Blob> {
        const response = await httpClient.get<Blob>(`/api/v1/pv-analysis/variations/${uuid}/map-screenshot-image`, {
            responseType: 'blob',
        });
        return response.data;
    },

    mapPvCalculatorDtoToAppDto(pvCalculatorResult: PVResultDto): PvAnalysisVariationCalculatorResult {
        return {
            amortizationYears: pvCalculatorResult.amortization_years,
            balanceAfter30Years: pvCalculatorResult.balance_30_years,
            initialInvestment: pvCalculatorResult.initial_investment,
            initialInvestmentInclFunding: pvCalculatorResult.initial_investment_incl_funding,
            profitLossAccumulated: pvCalculatorResult.profit_loss_accumulated,
            rateOfReturn: pvCalculatorResult.rate_of_return,
            annualProduction: pvCalculatorResult.design_kwh_per_year_ac,
            kwp: pvCalculatorResult.design_kwp,
            batteryCapacity: pvCalculatorResult.battery_capacity,
            ratioOwnUse: pvCalculatorResult.ratio_own_use,
            loadSummary: pvCalculatorResult.load_summary,
            co2Savings: pvCalculatorResult.co2_savings,
            trees: pvCalculatorResult.trees,
            pvCalculatorResponseData: pvCalculatorResult,
        };
    },
};
