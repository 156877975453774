import { BBox, MultiPolygon, Polygon, Position } from 'geojson';

export interface GeoLocation {
    address: string;
    lat: number;
    lng: number;
}

export enum Layers {
    SOLAR_POTENTIAL = 'solar-potential',
}

export interface SolarPotentialSearch {
    maxSystemCapacityAdjustment?: number;
}

export interface BuildingSearch extends SolarPotentialSearch {
    uuid: string;
}

export interface LocationSearch extends SolarPotentialSearch {
    location: {
        lat: number;
        lng: number;
    };
    radius: number;
    layers: Layers[];
}

export interface FederalState {
    code: string;
    name: string;
}

export interface Municipality {
    code: string;
    name: string;
    federalState: FederalState;
}

export type RealEstateGeometry = Polygon | MultiPolygon;

export interface Plot {
    code: string;
    name: string;
    box: BBox;
    geometry: RealEstateGeometry;
    municipality: Municipality;
    buildings: Building[];
}

export interface BuildingFunction {
    code: string;
    name: string;
}

export interface Building {
    code: string;
    name: string;
    box: BBox;
    geometry: RealEstateGeometry;
    buildingFunction: BuildingFunction;
    roof: Roof;
}

export interface RealEstate {
    count: number;
    locationSearch: {
        lat: number;
        lng: number;
        radius: number;
        layers: Layers[];
    };
    buildings: Building[];
}

export interface RoofSegmenetSolarPotential {
    panelsCount: number;
    panelCapacityWatts: number;
    yearlyEnergyDcKwh: number;
    moduleSizeAssumption: number;
    kwp: number;
    normalizedKwp: number;
    kwhPerKwp: number;
    dataSourceProcessedAt: Date;
    panelHeightMeters: number;
    panelWidthMeters: number;
    panelLifetimeYears: number;
}

export interface RoofSegment {
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegmentSolarPotential: RoofSegmenetSolarPotential;
    tiltDegrees: number;
    azimuthDegrees: number;
}

export interface Roof {
    code: string;
    center: Position;
    bbox: BBox;
    area: number;
    roofSegments: RoofSegment[];
}
