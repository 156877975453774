import * as yup from 'yup';

export const INITIAL_FORM_VALUES = {
    pvAnalysisName: '',
    name: '',
};

export const formValidationSchema = yup.object({
    pvAnalysisName: yup.string().required(),
    name: yup.string().required(),
});
